import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import userIcon from "../images/user.svg";

export default function Navbar() {
  const { user, logoutUser } = useContext(AuthContext);

  return (
    <nav
      className="navbar navbar-top navbar-expand-md navbar-darker"
      id="navbar-main"
    >
      <div className="container-fluid">
        <a
          className="h4 mb-0 text-uppercase d-none d-lg-inline-block text-white font-weight-900"
          href="#!"
        >
          Dashboard
        </a>
        <ul className="navbar-nav align-items-center d-none d-md-flex">
          <li className="nav-item dropdown">
            <a
              className="nav-link pr-0"
              href="#!"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="media align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img alt="Image placeholder" src={userIcon} />
                </span>
                <div className="media-body ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold text-white">
                    {user.username}
                  </span>
                </div>
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
              <div className="dropdown-header noti-title">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </div>
              <Link to="/settings/" className="dropdown-item">
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </Link>
              <a
                href="https://discord.com"
                target="_blank"
                className="dropdown-item"
              >
                <i className="fa fa-headphones-alt" />
                <span>Support</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#!" onClick={logoutUser} className="dropdown-item">
                <i className="fa fa-sign-out-alt" />
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

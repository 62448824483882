import React from "react";
import Sidenav from "../bases/Sidenav";
import Footer from "../bases/Footer";
import Navbar from "../bases/Navbar";

export default function NotFound() {
  return (
    <div>
      <Sidenav />
      <div className="main-content">
        <Navbar />
        <div className="header bg-semi-dark pt-5 pt-md-8"></div>
        <div className="container-fluid mt--5">
          <div className="card">
            <div className="card-body text-center">
              <h3>Nothing Here</h3>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

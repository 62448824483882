import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.svg";
import AuthContext from "../context/AuthContext";
import userIcon from "../images/user.svg";

export default function Sidenav() {
  const { user, logoutUser } = useContext(AuthContext);
  return (
    <nav
      className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-dark"
      id="sidenav-main"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#sidenav-collapse-main"
          aria-controls="sidenav-main"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa-solid fa-bars text-muted"></i>
        </button>
        <a className="navbar-brand pt-0" href="/">
          <img src={logo} className="navbar-brand-img" alt="..." />
        </a>
        <ul className="nav align-items-center d-md-none">
          <li className="nav-item dropdown">
            <a
              className="nav-link"
              href="#!"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="media align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img alt="Image placeholder" src={userIcon} />
                </span>
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
              <div className="dropdown-header noti-title">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </div>
              <Link to="/settings/" className="dropdown-item">
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </Link>
              <a href="https://discord.com" className="dropdown-item">
                <i className="fa fa-headphones-alt" />
                <span>Support</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#!" onClick={logoutUser} className="dropdown-item">
                <i className="fa fa-sign-out-alt" />
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
        <div
          className="collapse navbar-collapse bg-dark"
          id="sidenav-collapse-main"
        >
          <div className="navbar-collapse-header d-md-none">
            <div className="row">
              <div className="col-6 collapse-brand">
                <a href="#!">
                  <img src={logo} />
                </a>
              </div>
              <div className="col-6 collapse-close">
                <button
                  type="button"
                  className="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#sidenav-collapse-main"
                  aria-controls="sidenav-main"
                  aria-expanded="false"
                  aria-label="Toggle sidenav"
                >
                  <i className="fa-solid fa-circle-plus text-muted"></i>
                </button>
              </div>
            </div>
          </div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link text-white" to={"/"}>
                <i className="bi bi-tv" /> Dashboard
              </Link>
            </li>
          </ul>
          <hr className="my-3" />

          <h6 className="navbar-heading text-muted">Services</h6>

          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link text-muted" to={"/discordbot/"}>
                <i className="bi bi-robot" /> Discord Bot Hosting
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-muted" to={"/minecraft/"}>
                <i className="bi bi-box" /> Minecraft Server
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-muted" to={"/vps/"}>
                <i className="bi bi-cpu" /> Cloud VPS
              </Link>
            </li>
            {/* <li className="nav-item">
                                                    <Link className="nav-link text-muted" to={"/database/"}>
                                                            <i className="bi bi-server"/> Database Hosting
                                                    </Link>
                                            </li> */}
          </ul>
          <hr className="my-3" />

          <h6 className="navbar-heading text-muted">Your Account</h6>

          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link text-muted" to={"/billing/"}>
                <i className="bi bi-wallet2" />
                Billing
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-muted" to={"/settings/"}>
                <i className="bi bi-sliders" /> Settings
              </Link>
            </li>
          </ul>
          <hr className="my-3" />

          <h6 className="navbar-heading text-muted">
            Links&nbsp;
            <i className="fa fa-external-link-alt" />
          </h6>

          <ul className="navbar-nav mb-md-3">
            <li className="nav-item">
              <a
                className="nav-link text-muted"
                href="https://google.com"
                target="_blank"
              >
                <i className="fa fa-question-circle text-danger" /> How to
                deploy
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-muted"
                href="https://discord.com"
                target="_blank"
              >
                <i className="fa fa-headphones-alt text-danger" /> Support
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

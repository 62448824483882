import { createContext, useState } from "react";

const ResourceContext = createContext();

export default ResourceContext;

export const ResourceProvider = ({ children }) => {
  const [isDatabaseCreate, setDatabaseCreate] = useState(false);
  const [isMcHostCreate, setMcHostCreate] = useState(false);
  const [isDiscordBotCreate, setDiscordBotCreate] = useState(false);
  const [isVPSCreate, setVPSCreate] = useState(false);

  let contextData = {
    isDatabaseCreate,
    isMcHostCreate,
    isDiscordBotCreate,
    isVPSCreate,
    setDatabaseCreate,
    setMcHostCreate,
    setDiscordBotCreate,
    setVPSCreate,
  };

  return (
    <ResourceContext.Provider value={contextData}>
      {children}
    </ResourceContext.Provider>
  );
};

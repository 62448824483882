import React, { useState } from "react";
import { useContext } from "react";
import ResourceContext from "../../context/ResourceContext";
import useAxios from "../../utils/useAxios";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingDots from "../animations/LoadingDots";

export default function McLaunchModel() {
  const api = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [name, setName] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEgg, setSelectedEgg] = useState(null);
  const { setMcHostCreate } = useContext(ResourceContext);

  const fetchData = async () => {
    const response = await api.get("/minecraft/config/");
    return response.data;
  };

  const setPlanFromId = (event) => {
    setSelectedPlan(data.plans.at(event.target.value));
  };
  const launchMinecraft = async (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Enter a valid name");
      return;
    } else if (!selectedPlan) {
      toast.error("Select a valid plan");
      return;
    } else if (!selectedEgg) {
      toast.error("Select a valid server type");
      return;
    } else if (!selectedNode) {
      toast.error("Select a valid node");
      return;
    }
    const response = await api.post("/minecraft/", {
      name: name,
      plan_name: selectedPlan.name,
      node_id: selectedNode,
      egg_id: selectedEgg,
    });
    if (response.status === 201) {
      let minecraft = queryClient.getQueryData("minecraft");
      if (minecraft !== undefined) {
        minecraft.unshift(response.data);
        queryClient.setQueryData("minecraft", minecraft);
      }
      setMcHostCreate(false);
      navigate("/minecraft/", { replace: true });
      toast.success("Minecraft server Created");
    } else {
      toast.error("Something went wrong !!!");
      setMcHostCreate(false);
    }
  };
  const { isLoading, error, data } = useQuery("mc_plans", fetchData, {
    refetchOnWindowFocus: false,
  });
  if (isLoading)
    return (
      <div className="modal-body p-5">
        <LoadingDots />
      </div>
    );
  if (error)
    return (
      <div className="modal-body p-5">
        <div className="text-center">
          An error has occurred: {error.message}
        </div>
      </div>
    );

  return (
    <div>
      <div className="modal-body">
        <form id="app-launch" method="post" action="#">
          <div className="form-row">
            <div className="form-group col-md-8">
              <label>Name of Minecraft server</label>
              <input
                type="text"
                className="form-control text-darker"
                pattern="[^' ']+"
                onChange={(e) => setName(e.target.value)}
                name="name"
                id="inputCity"
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label>Plan</label>
              <select
                id="inputState"
                className="form-control text-darker"
                name="plan"
                required
                onChange={setPlanFromId}
              >
                <option selected disabled>
                  Choose...
                </option>
                {data.plans
                  ? data.plans.map((plan, i) => (
                      <option value={i}>{plan.name}</option>
                    ))
                  : null}
              </select>
            </div>
            <div className="form-group col-md-4">
              <label>Node</label>
              <select
                id="inputState"
                className="form-control text-darker"
                name="plan"
                required
                onChange={(e) => setSelectedNode(e.target.value)}
              >
                <option selected disabled>
                  Choose...
                </option>
                {data.nodes
                  ? data.nodes.map((node) => (
                      <option value={node.id}>{node.name}</option>
                    ))
                  : null}
              </select>
            </div>
            <div className="form-group col-md-4">
              <label>Server Type</label>
              <select
                id="inputState"
                className="form-control text-darker"
                name="plan"
                required
                onChange={(e) => setSelectedEgg(e.target.value)}
              >
                <option selected disabled>
                  Choose...
                </option>
                {data.eggs
                  ? data.eggs.map((egg) => (
                      <option value={egg.id}>{egg.name}</option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-2">
              <label>CPU</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  id="dbotcpu"
                  className="form-control text-darker"
                  value={selectedPlan ? selectedPlan.cpu : 0}
                  name="cpu"
                  readOnly={true}
                />
                <div class="input-group-append">
                  <span class="input-group-text">vCore</span>
                </div>
              </div>
            </div>
            <div className="form-group col-md-2">
              <label>RAM</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  id="dbotram"
                  className="form-control text-darker"
                  value={selectedPlan ? selectedPlan.ram : 0}
                  name="ram"
                  readOnly={true}
                />
                <div class="input-group-append">
                  <span class="input-group-text">GB</span>
                </div>
              </div>
            </div>
            <div className="form-group col-md-2">
              <label>Disk</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  id="dbotdisk"
                  className="form-control text-darker"
                  value={selectedPlan ? selectedPlan.memory : 0}
                  name="disk"
                  readOnly={true}
                />
                <div class="input-group-append">
                  <span class="input-group-text">GB</span>
                </div>
              </div>
            </div>
            <div className="form-group col-md-2">
              <label>Rate (pm)</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  value={selectedPlan ? selectedPlan.rate : 0}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => setMcHostCreate(false)}
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="submit"
          form="app-launch"
          className="btn btn-default"
          onClick={launchMinecraft}
        >
          Launch
        </button>
      </div>
    </div>
  );
}

import React, { useContext, useEffect } from "react";
import ResourceContext from "../context/ResourceContext";
import McLaunchModel from "./model_components/McLaunchModel";
import DiscordLaunchModel from "./model_components/DiscordLaunchModel";
import VpsLaunchModel from "./model_components/VpsLaunchModel";
import AuthContext from "../context/AuthContext";

export default function Models() {
  const { user } = useContext(AuthContext);
  const {
    isDatabaseCreate,
    isMcHostCreate,
    isDiscordBotCreate,
    isVPSCreate,
    setVPSCreate,
    setDatabaseCreate,
    setMcHostCreate,
    setDiscordBotCreate,
  } = useContext(ResourceContext);

  useEffect(() => {
    if (isVPSCreate || isMcHostCreate || isDiscordBotCreate)
      document.body.classList.add("modal-open");
    else document.body.classList.remove("modal-open");
  }, [isDiscordBotCreate, isMcHostCreate, isVPSCreate]);

  return user ? (
    <>
      {/* <div
        className={`modal fade ${isDatabaseCreate ? "show" : ""}`}
        id="databaseCreateModalCenter"
        role="dialog"
        style={{ display: isDatabaseCreate ? "block" : "none" }}
        aria-labelledby="databaseCreateModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="databaseCreateModalCenterLongTitle"
              >
                Provision a database
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setDatabaseCreate(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <DatabaseLaunchModel />
          </div>
        </div>
      </div> */}
      <div
        className={`modal fade ${isMcHostCreate ? "show" : ""}`}
        id="databaseCreateModalCenter"
        role="dialog"
        style={{ display: isMcHostCreate ? "block" : "none" }}
        aria-labelledby="databaseCreateModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="databaseCreateModalCenterLongTitle"
              >
                Launch Minecraft Server
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setMcHostCreate(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <McLaunchModel />
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${isDiscordBotCreate ? "show" : ""}`}
        id="databaseCreateModalCenter"
        role="dialog"
        style={{ display: isDiscordBotCreate ? "block" : "none" }}
        aria-labelledby="databaseCreateModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="databaseCreateModalCenterLongTitle"
              >
                Host a Discord Bot
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setDiscordBotCreate(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <DiscordLaunchModel />
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${isVPSCreate ? "show" : ""}`}
        id="databaseCreateModalCenter"
        role="dialog"
        style={{ display: isVPSCreate ? "block" : "none" }}
        aria-labelledby="databaseCreateModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="databaseCreateModalCenterLongTitle"
              >
                Launch a VPS
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setVPSCreate(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <VpsLaunchModel />
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

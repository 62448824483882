import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VPSHosting from "./pages/VPSHosting";
import Managed from "./pages/Managed";
import McHosting from "./pages/MineCraftHosting";
import Authentication from "./pages/Authentication";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import { ResourceProvider } from "./context/ResourceContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Verification from "./pages/Verification";
import Billing from "./pages/Billing";
import Settings from "./pages/Settings";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Models from "./components/Models";
import McManage from "./pages/manage/McManage";
import NotFound from "./pages/NotFound";
import VPSManage from "./pages/manage/VPSManage";
import DiscordBotManage from "./pages/manage/DiscordBotManage";

class App extends Component {
  render() {
    const queryClient = new QueryClient();

    return (
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ResourceProvider>
              <Routes>
                <Route
                  path={"/verify/:code"}
                  exact
                  element={<Verification />}
                />
                <Route path={"/login/"} exact element={<Authentication />} />
                <Route
                  path={"/"}
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/vps/"}
                  element={
                    <PrivateRoute>
                      <VPSHosting />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/vps/:vpsId"}
                  element={
                    <PrivateRoute>
                      <VPSManage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/discordbot/"}
                  element={
                    <PrivateRoute>
                      <Managed />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/discordbot/:botId/"}
                  element={
                    <PrivateRoute>
                      <DiscordBotManage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/minecraft/"}
                  element={
                    <PrivateRoute>
                      <McHosting />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/minecraft/:mcId"}
                  element={
                    <PrivateRoute>
                      <McManage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/billing/"}
                  element={
                    <PrivateRoute>
                      <Billing />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/settings/"}
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"/notfound/"}
                  element={
                    <PrivateRoute>
                      <NotFound />
                    </PrivateRoute>
                  }
                />
              </Routes>
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme={"colored"}
              />
              <Models />
              <ReactQueryDevtools />
            </ResourceProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    );
  }
}

export default App;

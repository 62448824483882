import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";

export default function Verification() {
  const { verifyUser } = useContext(AuthContext);
  const { code } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    code ? verifyUser(code) : navigate("/login/");
  }, []);
  return <h3 style={{ color: "#000" }}>Verifying code.... please wait...</h3>;
}

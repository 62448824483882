import React from 'react'
import DashStatusCard from "../DashStatusCard";
import WalletWidget from '../WalletWidget';
import useAxios from '../../utils/useAxios';
import { useQuery } from 'react-query';
import LoadingDots from '../animations/LoadingDots';

export default function DashdataSection() {

    const api = useAxios()

    const fetchData = async () => {
        const response = await api.get("/dashboard/");
        return response.data
    }

    const { isLoading, error, data } = useQuery('dashboard', fetchData, { refetchOnWindowFocus: false });

    if (isLoading) return <div className="container p-5"><LoadingDots /></div>

    if (error) return <div className="container p-5"><div className="text-center">An error has occurred: {error.message}</div></div>

    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className="col-sm-12 mt-3 d-lg-none d-sm-block mb-3 border-top border-primary pt-3">
                    <WalletWidget txn_btn={true} />
                </div>
                <hr />
                <div className={"col-xl-4 col-sm-12 mt-3 mb-3 rounded-3"}>
                    <DashStatusCard service_name={"Discord Bots"} instance_count={data.discord_bots ? data.discord_bots.instances : 0} monthly_cost={data.discord_bots ? data.discord_bots.rate_pm : 0} icon={"bi bi-robot"} />
                </div>
                <div className={"col-xl-4 col-sm-12 mt-3 mb-3 rounded-3"}>
                    <DashStatusCard service_name={"MC Server "} instance_count={data.mc_servers ? data.mc_servers.instances : 0} monthly_cost={data.mc_servers ? data.mc_servers.rate_pm : 0} icon={"bi bi-box"} />
                </div>
                <div className={"col-xl-4 col-sm-12 mt-3 mb-3 rounded-3"}>
                    <DashStatusCard service_name={"VPS Server"} instance_count={data.vps_servers ? data.vps_servers.instances : 0} monthly_cost={data.vps_servers ? data.vps_servers.rate_pm : 0} icon={"bi bi-cpu"} />
                </div>
                {/* <div className={"col-xl-3 col-md-6 col-sm-12 mt-3 mb-3 rounded-3"}>
            <DashStatusCard service_name={"Database"} instance_count={data.databases? data.databases.instances: 0} monthly_cost={data.databases? data.databases.rate_pm: 0} icon={"bi bi-server"}/>
        </div> */}
            </div>
        </div>
    )
}

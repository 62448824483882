import React from "react";
import paypal from "../images/paypal.png";

import useAxios from "../utils/useAxios";
import { useQuery } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";
import LoadingDots from "./animations/LoadingDots";

function Shell({ children }) {
  return (
    <div className="card">
      <div className="card-body">{children}</div>
    </div>
  );
}

export default function PaypalCard({ link_btn }) {
  const api = useAxios();
  const navigate = useNavigate();
  const fetchData = async () => {
    const response = await api.get("/paypal/");
    return response.data;
  };
  const { isLoading, error, data } = useQuery("paypal", fetchData, {
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <Shell>
        <LoadingDots />
      </Shell>
    );

  if (error)
    return (
      <Shell>
        <div className="text-center">
          An error has occurred: {error.message}
        </div>
      </Shell>
    );

  return (
    <Shell>
      <div className="row justify-content-between align-items-center">
        <div className="col">
          <img src={paypal} alt="Image placeholder" />
        </div>
        <div className="col-auto">
          {data.paypal_email ? (
            <span className="badge badge-lg badge-success">Active</span>
          ) : (
            <span className="badge badge-lg badge-danger">Inactive</span>
          )}
        </div>
      </div>
      <div className="my-2">
        <div className="h1">
          {data.paypal_email ? data.paypal_email : "N/A"}
        </div>
      </div>
      {data.paypal_id ? (
        <div className="row">
          <div className="col">
            <span className="h6 surtitle text-muted">Name</span>
            <span className="d-block h3">
              {data.paypal_id ? data.paypal_id : "N/A"}
            </span>
          </div>
        </div>
      ) : (
        <>
          {link_btn ? (
            <button
              className="btn btn-sm bg-gradient-info w-100 text-white"
              onClick={() => navigate("/billing/")}
            >
              Link
            </button>
          ) : (
            <div className="border border-default rounded px-2 py-1 text-center">
              <small>
                <b>Add credits to link your Paypal</b>
              </small>
            </div>
          )}
        </>
      )}
    </Shell>
  );
}

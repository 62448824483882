import React from "react";
import useAxios from "../../utils/useAxios";
import { useQuery } from "react-query";
import LoadingDots from "../animations/LoadingDots";
import { Link } from "react-router-dom";

export default function VPSList() {
  const api = useAxios();
  const fetchData = async () => {
    const response = await api.get("/vps/");
    return response.data;
  };
  const { isLoading, error, data } = useQuery("vps", fetchData, {
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <tbody>
        <tr>
          <td colSpan={7}>
            <LoadingDots />
          </td>
        </tr>
      </tbody>
    );

  if (error)
    return (
      <tbody>
        <tr>
          <td colSpan={7} className="text-center">
            An unexpected error occured: {error.message}
          </td>
        </tr>
      </tbody>
    );

  return (
    <tbody>
      {data.map((vps) => (
        <tr>
          <th scope="row">
            <Link to={`/vps/${vps.id}/`}>{vps.name ? vps.name : "N/A"}</Link>
          </th>
          <td>{vps.plan ? vps.plan : "N/A"}</td>
          <td>{vps.rate ? "$" + vps.rate : "N/A"}</td>
          <td>
            {vps.auto_renew ? (
              <i className="bi bi-check-circle-fill text-success" />
            ) : (
              <i class="bi bi-x-circle-fill text-danger" />
            )}
          </td>
          <td>{vps.ip ? vps.ip : "N/A"}</td>
          <td>{vps.id ? vps.id : "N/A"}</td>
          <td>
            {vps.running ? (
              <i className="bi bi-check-circle-fill text-success" />
            ) : (
              ""
            )}{" "}
            {vps.status ? vps.status : "N/A"}
          </td>
        </tr>
      ))}
    </tbody>
  );
}

import React, { useContext } from "react";
import Sidenav from "../bases/Sidenav";
import Navbar from "../bases/Navbar";
import Footer from "../bases/Footer";
import managed from "../images/deploy.png";
import vps from "../images/cloud-vps.png";
import dbs from "../images/database.png";
import dedicated from "../images/dedicated.png";
import WalletWidget from "../components/WalletWidget";
import ResourceContext from "../context/ResourceContext";
import DashStatsSection from "../components/sections/DashStatsSection";
import PaypalCard from "../components/PaypalCard";

export default function Dashboard() {
  const {
    setDatabaseCreate,
    setVPSCreate,
    setDiscordBotCreate,
    setMcHostCreate,
  } = useContext(ResourceContext);
  return (
    <div>
      <Sidenav />
      <div className="main-content">
        <Navbar />
        <div className="header bg-semi-dark pt-5 pt-md-8"></div>
        <div className="container-fluid mt--5">
          <div className="row">
            <div className="col-xl-9 col-md-12">
              <div className={"row"}>
                <div className="col-xl-12 col-md-12 mb-1">
                  <div className="card shadow-none border border-default">
                    <div className="card-header">
                      <h3>Services</h3>
                    </div>
                    <DashStatsSection />
                  </div>
                </div>
                <div className="col-xl-12 col-md-12 mt-2">
                  <div className="card shadow-lg">
                    <div className="card-header">
                      <h3>Deploy your apps to cloud</h3>
                    </div>
                    <div className={"card-body"}>
                      <div className={"container px-1"}>
                        <div className={"row"}>
                          <div className={"col-xl-4 col-sm-12 mb-3"}>
                            <a
                              href="#!"
                              onClick={() => setDiscordBotCreate(true)}
                            >
                              <div className={"card p-2"}>
                                <h3 className={"text-cadet-grey"}>
                                  Deploy a bot
                                </h3>
                                <p className={"mt--2 text-dark"}>
                                  on managed hosting
                                </p>
                                <img
                                  src={managed}
                                  alt={"Managed Hosting"}
                                  width={"70"}
                                />
                              </div>
                            </a>
                          </div>
                          <div className={"col-xl-4 col-sm-12 mb-3"}>
                            <a href="#!" onClick={() => setMcHostCreate(true)}>
                              <div className={"card p-2"}>
                                <h3 className={"text-cadet-grey"}>
                                  Spin up a server
                                </h3>
                                <p className={"mt--2 text-dark"}>
                                  with Minecraft hosting
                                </p>
                                <img
                                  src={dedicated}
                                  alt={"Dedicated Hosting"}
                                  width={"70"}
                                />
                              </div>
                            </a>
                          </div>
                          <div className={"col-xl-4 col-sm-12 mb-3"}>
                            <a href="#!" onClick={() => setVPSCreate(true)}>
                              <div className={"card p-2"}>
                                <h3 className={"text-cadet-grey"}>
                                  Launch an instance
                                </h3>
                                <p className={"mt--2 text-dark"}>
                                  with cloud vps hosting
                                </p>
                                <img src={vps} alt={"Cloud VPS"} width={"70"} />
                              </div>
                            </a>
                          </div>
                          {/* <div className={"col-xl-3 col-md-6 mb-3"}>
                                                          <a href="#!" onClick={()=>setDatabaseCreate(true)}>
                                                              <div className={"card p-2"}>
                                                                  <h3 className={"text-cadet-grey"}>Provision a database</h3>
                                                                  <p className={"mt--2 text-dark"}>with database hosting</p>
                                                                  <img src={dbs} alt={"Database"} width={"70"}/>
                                                              </div>
                                                          </a>
                                                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-xl-3 col-md-12 d-none d-lg-none d-xl-block"}>
              <div className={"row"}>
                <div className="col-xl-12 col-md-6 mb-1 mt-2">
                  <WalletWidget txn_btn={true} />
                </div>
                <div className="col-xl-12 col-md-6 mb-1 mt-2">
                  <PaypalCard link_btn={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

import React from "react";
import useAxios from "../utils/useAxios";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import LoadingDots from "./animations/LoadingDots";

function Shell({ children }) {
  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col-8">
            <h3 className="mb-0">Edit profile </h3>
          </div>
          <div className="col-4 text-right">
            <button
              type="submit"
              form="personalDetailsForm"
              className="btn btn-sm btn-default"
            >
              Save Details
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
}
export default function ProfileCard() {
  const api = useAxios();
  const queryClient = useQueryClient();

  const updatePersonalData = async (e) => {
    e.preventDefault();
    const response = await api.post("/settings/", {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      address: e.target.address.value,
      country: e.target.country.value,
      city: e.target.city.value,
      pincode: e.target.pincode.value,
    });
    if (response.status === 200) toast.success("Data saved");
    else toast.error("Something went wrong");
  };

  const mutation = useMutation(updatePersonalData, {
    onSuccess: (updateData) => {
      queryClient.setQueryData("settings", data, updateData);
    },
  });

  const fetchData = async () => {
    const response = await api.get("/settings/");
    return response.data;
  };
  const { isLoading, error, data } = useQuery("settings", fetchData, {
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <Shell>
        <LoadingDots />
      </Shell>
    );

  if (error)
    return (
      <Shell>
        <div className="text-center">
          An error has occurred: {error.message}
        </div>
      </Shell>
    );

  return (
    <Shell>
      <form id="personalDetailsForm" onSubmit={updatePersonalData}>
        <h6 className="heading-small text-muted mb-4">User information</h6>
        <div className="pl-lg-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label
                  className="form-control-label"
                  htmlhtmlFor="input-username"
                >
                  Username
                </label>
                <input
                  type="text"
                  id="input-username"
                  className="form-control"
                  defaultValue={data.username}
                  readOnly
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="form-control-label" htmlhtmlFor="input-email">
                  Email address
                </label>
                <input
                  type="email"
                  id="input-email"
                  className="form-control"
                  defaultValue={data.email}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label
                  className="form-control-label"
                  htmlhtmlFor="input-first-name"
                >
                  First name
                </label>
                <input
                  type="text"
                  id="input-first-name"
                  name="first_name"
                  className="form-control"
                  defaultValue={data.first_name}
                  placeholder={!data.first_name ? "Not set" : ""}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label
                  className="form-control-label"
                  htmlhtmlFor="input-last-name"
                >
                  Last name
                </label>
                <input
                  type="text"
                  id="input-last-name"
                  name="last_name"
                  className="form-control"
                  defaultValue={data.last_name}
                  placeholder={!data.last_name ? "Not set" : ""}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="my-4" />
        <h6 className="heading-small text-muted mb-4">Contact information</h6>
        <div className="pl-lg-4">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label
                  className="form-control-label"
                  htmlhtmlFor="input-address"
                >
                  Address
                </label>
                <input
                  id="input-address"
                  className="form-control"
                  name="address"
                  defaultValue={!data ? "" : data.customer.meta.address}
                  placeholder={!data.customer.meta.address ? "Not set" : ""}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label className="form-control-label" htmlhtmlFor="input-city">
                  City
                </label>
                <input
                  type="text"
                  id="input-city"
                  className="form-control"
                  name="city"
                  defaultValue={!data ? "" : data.customer.meta.city}
                  placeholder={!data.customer.meta.city ? "Not set" : ""}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label
                  className="form-control-label"
                  htmlhtmlFor="input-country"
                >
                  Country
                </label>
                <input
                  type="text"
                  id="input-country"
                  name="country"
                  className="form-control"
                  defaultValue={!data ? "" : data.customer.meta.country}
                  placeholder={!data.customer.meta.country ? "Not set" : ""}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label
                  className="form-control-label"
                  htmlhtmlFor="input-country"
                >
                  Postal code
                </label>
                <input
                  type="number"
                  id="input-postal-code"
                  name="pincode"
                  className="form-control"
                  defaultValue={!data ? "" : data.customer.meta.pincode}
                  placeholder={!data.customer.meta.pincode ? "Not set" : ""}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </Shell>
  );
}

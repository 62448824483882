import React from 'react'
import useAxios from '../../utils/useAxios';
import { useQuery } from 'react-query';
import LoadingDots from '../animations/LoadingDots';

export default function OrderList() {
    const api = useAxios();
    const fetchData = async() => {
        const response = await api.get("/orders/");
        return response.data
    }

    const { isLoading, error, data } = useQuery('orders', fetchData, {refetchOnWindowFocus: false});

    if (isLoading) return <tbody><tr><td colSpan={5}><LoadingDots/></td></tr></tbody>

    if (error) return <tbody><tr><td colSpan={5} className="text-center">An unexpected error occured: {error.message}</td></tr></tbody>
    return (
    <tbody>
    {data ? 
    data.map((order) => (
        <tr>
            <td><b>{order.id ? order.id : "N/A"}</b></td>
            <td><b>{order.amount ? "$"+order.amount : "N/A"}</b></td>
            <td><b>{order.create_time ? order.create_time : "N/A"}</b></td>
            <td><b>{order.allocation_id ? order.allocation_id : "N/A"}</b></td>
            <td>{order.success ? <i className="bi bi-check-circle-fill text-success"/> : ""} <b>{order.status ? order.status : "N/A"}</b></td>
        </tr>
    ))
    :
    <tr>No Entry</tr>
    }
    </tbody>
    )
}

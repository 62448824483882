import React from "react";

export default function ({ service_name, instance_count, monthly_cost, icon }) {
  return (
    <div className="card bg-cadet-grey">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h5 className="card-title text-uppercase text-muted mb-0">
              {service_name}
            </h5>
            <span className="h2 font-weight-bold mb-0 text-white">
              {instance_count}
            </span>
          </div>
          <div className="col-auto">
            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
              <i className={`${icon} text-cadet-grey`} />
            </div>
          </div>
        </div>
        <p className="mt-3 mb-0 text-sm">
          <span className="text-danger mr-2">
            <i className="bi bi-currency-dollar" />
            {monthly_cost}
          </span>
          <span className="text-nowrap text-white">monthly</span>
        </p>
      </div>
    </div>
  );
}

import React from 'react'
import useAxios from "../../utils/useAxios";
import { useQuery } from 'react-query';
import LoadingDots from '../animations/LoadingDots';

export default function TransactionList() {

    const api = useAxios();
    const fetchData = async() => {
        const response = await api.get("/transactions/");
        return response.data
    }

    const { isLoading, error, data } = useQuery('transactions', fetchData, {refetchOnWindowFocus: false});

    if (isLoading) return <tbody><tr><td colSpan={5}><LoadingDots/></td></tr></tbody>

    if (error) return <tbody><tr><td colSpan={5} className="text-center">An unexpected error occured: {error.message}</td></tr></tbody>
    return (
        <tbody>
            {data ?
            data.map((transaction) => (
            <tr>
                <td><b>{transaction.id ? transaction.id : "N/A"}</b></td>
                <td><b>{transaction.amount ? "$"+transaction.amount : "N/A"}</b></td>
                <td><b>{transaction.create_time ? transaction.create_time : "N/A"}</b></td>
                {/* <td><b>{transaction.purpose ? transaction.purpose : "N/A"}</b></td> */}
                <td>{transaction.success ? <i className="bi bi-check-circle-fill text-success"></i> : ""} <b>{transaction.status ? transaction.status : "N/A"}</b></td>
                <td>{transaction.success ? <a href="#!"><i className="bi bi-eye"></i> View</a> : "N/A" }</td>
            </tr>
            ))
            :
            <tr>No Entries</tr>
            }
        </tbody>
    )
}

import React, { useContext, useState } from "react";
import Sidenav from "../bases/Sidenav";
import Navbar from "../bases/Navbar";
import Footer from "../bases/Footer";
import ProfileCard from "../components/ProfileCard";
import useAxios from "../utils/useAxios";
import { toast } from "react-toastify";
import AuthContext from "../context/AuthContext";

export default function Settings() {
  const api = useAxios();
  const { logoutUser } = useContext(AuthContext);
  const [agree, setAgree] = useState(true);
  const closeAccount = async () => {
    const response = await api.post("/paypal/").catch((error) => {
      if (error.response.status === 406)
        toast.error(
          "Terminate your running resources before closing your account"
        );
      else toast.error("Something went wrong... Contact administrator");
    });
    if (response.status === 202) {
      toast.success("Account closing successful. Logging you out");
      logoutUser();
    }
  };
  return (
    <div>
      <Sidenav />
      <div className="main-content">
        <Navbar />
        <div className="header bg-semi-dark pt-5 pt-md-8"></div>
        <div className="container-fluid mt--5">
          <div className="row">
            <div className="col-xl-10 col-md-12">
              <div className={"row"}>
                <div className="col-xl-12 col-md-12 mb-1">
                  <ProfileCard />
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-12 mt-5">
              <div className={"row"}>
                <div className="col-xl-12 col-md-12 mb-1">
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h3 className="mb-0">Danger Zone </h3>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={() => setAgree(!agree)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          I understand this action cannot be undone. After
                          closing this account i cannot open another with the
                          same email address.
                        </label>
                      </div>
                      <button
                        className="btn btn-sm btn-danger"
                        disabled={agree}
                        onClick={closeAccount}
                      >
                        Close Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

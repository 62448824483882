import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      {/*<div className="row align-items-center justify-content-xl-between">*/}
      {/*  <div className="col-xl-12">*/}
      {/*    <div className="text-center text-xl-left text-muted">*/}
      {/*      <a href="#!" className="font-weight-bold ml-1 text-dark">BotCloud Dashboard</a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </footer>
  );
}

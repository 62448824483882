import React, { useContext } from "react";
import Sidenav from "../bases/Sidenav";
import Navbar from "../bases/Navbar";
import Footer from "../bases/Footer";
import VPSList from "../components/sections/VPSList";
import ResourceContext from "../context/ResourceContext";

export default function VPSHosting() {
  const { setVPSCreate } = useContext(ResourceContext);
  return (
    <div>
      <Sidenav />
      <div className="main-content">
        <Navbar />

        <div className="header bg-semi-dark pt-5 pt-md-8"></div>

        <div className="container-fluid mt--4">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">VPS Instances</h3>
                    </div>
                    <div className="col text-right">
                      <button
                        href="#!"
                        className="btn btn-sm btn-default"
                        onClick={() => setVPSCreate(true)}
                      >
                        Launch Instance
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">plan</th>
                        <th scope="col">rate (pm)</th>
                        <th scope="col">auto renew</th>
                        <th scope="col">ip</th>
                        <th scope="col">Resource id</th>
                        <th scope="col">status</th>
                      </tr>
                    </thead>
                    <VPSList />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModalCenter"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Launch new app
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form id="app-launch" method="post" action="#">
                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label>Name of your app</label>
                      <input
                        type="text"
                        className="form-control text-darker"
                        pattern="[^' ']+"
                        name="name"
                        id="inputCity"
                        required
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label>Plan</label>
                      <select
                        id="inputState"
                        className="form-control text-darker"
                        name="plan"
                        required
                      >
                        <option selected disabled>
                          Choose...
                        </option>
                        <option value="Base">Base</option>
                        <option value="Standard">Standard</option>
                        <option value="Premium" disabled>
                          Premium [N/A]
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label>Stack</label>
                      <input
                        type="text"
                        id="stack"
                        className="form-control text-darker"
                        name="stack"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label>Rate</label>
                      <input
                        type="text"
                        className="form-control text-darker"
                        name="rate"
                        value="0"
                        id="rate"
                      />
                    </div>
                  </div>
                  <p className="font-weight-500">
                    <b>Note:</b> You won't be charged unless you deploy the app
                  </p>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  form="app-launch"
                  className="btn btn-primary"
                >
                  Launch
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

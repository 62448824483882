import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { useQuery, useQueryClient } from "react-query";
import { PayPalButton } from "react-paypal-button-v2";
import { toast } from "react-toastify";
import LoadingDots from "./animations/LoadingDots";

function Shell({ children }) {
  return (
    <div className="card bg-cadet-grey">
      <div className="card-body">{children}</div>
    </div>
  );
}

export default function WalletWidget({ txn_btn }) {
  const api = useAxios();
  const queryClient = useQueryClient();
  const [click, setClick] = useState(false);
  const [amount, setAmount] = useState(10);

  const fetchData = async () => {
    const response = await api.get("/wallet/");
    return response.data;
  };
  const { isLoading, error, data } = useQuery("wallet", fetchData, {
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <Shell>
        <LoadingDots />
      </Shell>
    );

  if (error)
    return (
      <Shell>
        <div className="text-light mt-2 text-center">
          An error occured: {error.message}
        </div>
      </Shell>
    );

  return (
    <>
      <Shell>
        {txn_btn ? (
          <Link to="/billing/">
            <button className="btn btn-sm bg-gradient-red float-right">
              Transactions
            </button>
          </Link>
        ) : (
          ""
        )}
        <div className="mb-2">
          <sup className="text-white">$</sup>{" "}
          <span className="h2 text-white">{data.credits}</span>
          <div className="text-light mt-2 text-sm">Your current balance</div>
        </div>
        <button
          className="btn btn-sm btn-block bg-gradient-red"
          onClick={() => setClick(true)}
        >
          Add credits
        </button>
      </Shell>
      <div
        class={`modal fade ${!click ? "" : "show"}`}
        id="paymentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="paymentModal"
        style={{ display: click ? "block" : "none" }}
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="paymentModalTitle">
                Add Credits
              </h5>
              <button
                type="button"
                class="close"
                onClick={() => setClick(!click)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="exampleInputEmail1">Amount (in $)</label>
                <input
                  type="number"
                  class="form-control"
                  min="1"
                  max="1000"
                  name="amount"
                  id="exampleInputEmail1"
                  onChange={(e) => setAmount(e.target.value)}
                  aria-describedby="emailHelp"
                  placeholder="10"
                />
                <small id="emailHelp" class="form-text text-muted">
                  Enter the amount you want to add.
                </small>
              </div>
              <PayPalButton
                amount={amount}
                shippingPreference="NO_SHIPPING"
                onSuccess={async (details, dataResp) => {
                  const response = await api.post("/wallet/", {
                    details: details,
                    data: dataResp,
                  });
                  queryClient.setQueryData("wallet", {
                    credits: response.data.credits,
                  });
                  queryClient.setQueryData("paypal", {
                    paypal_email: response.data.paypal_email,
                    paypal_id: response.data.paypal_id,
                  });
                  let transactions = queryClient.getQueryData("transactions");
                  if (transactions !== undefined) {
                    transactions.unshift(response.data.transaction);
                    queryClient.setQueryData("transactions", transactions);
                  }
                  setClick(false);
                  toast.success("Transaction Successful");
                  return;
                }}
                onError={(error) => {
                  toast.error(
                    "Unknown error. Contact support if money was debited."
                  );
                }}
                options={{
                  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import useAxios from "../utils/useAxios";
import { useQuery } from "react-query";
import LoadingDots from "./animations/LoadingDots";

function Shell({ children }) {
  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="mb-0">Game Panel</h3>
          </div>
          <div className="col text-right">
            <a
              href="https://panel.nodeslice.com"
              target="_blank"
              className="btn btn-sm btn-default"
            >
              Go to Panel
            </a>
          </div>
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
}

export default function PterodactylCard() {
  const api = useAxios();
  const fetchData = async () => {
    const response = await api.get("/pterodactyl/");
    return response.data;
  };

  const { isLoading, error, data } = useQuery("pterodactyl", fetchData, {
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <Shell>
        <LoadingDots />
      </Shell>
    );

  if (error)
    return (
      <Shell>
        <div className="text-light mt-2 text-center">
          An error occured: {error.message}
        </div>
      </Shell>
    );

  return (
    <Shell>
      <fieldset className="border border-default rounded p-3">
        <legend className="mr-auto ml-auto w-auto">
          <h5 className="h2 card-title mb-0">Pterodactyl Credentials</h5>
        </legend>
        <p className="text-monospace font-weight-bold">
          Username: {data.username ? data.username : "N/A"}
        </p>
        <p className="text-monospace font-weight-bold">
          Password: {data.password ? data.password : "N/A"}
        </p>
      </fieldset>
    </Shell>
  );
}

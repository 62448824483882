import React, { useContext } from "react";
import Sidenav from "../bases/Sidenav";
import Navbar from "../bases/Navbar";
import Footer from "../bases/Footer";
import DiscordBotsList from "../components/sections/DiscordBotsList";
import ResourceContext from "../context/ResourceContext";

export default function Managed() {
  const { setDiscordBotCreate } = useContext(ResourceContext);

  return (
    <div>
      <Sidenav />
      <div className="main-content">
        <Navbar />
        <div className="header bg-semi-dark pb-8 pt-5 pt-md-8"></div>
        <div className="container-fluid mt--7">
          <div className="row">
            <div className="col">
              <div className="card text-muted shadow">
                <div className="card-header border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Discord Bot Hosting</h3>
                    </div>
                    <div className="col text-right">
                      <button
                        href="#!"
                        className="btn btn-sm btn-default"
                        onClick={() => setDiscordBotCreate(true)}
                      >
                        Launch Server
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">App name</th>
                        <th scope="col">plan</th>
                        <th scope="col">rate (pm)</th>
                        <th scope="col">auto renew</th>
                        <th scope="col">resource id</th>
                        <th scope="col">status</th>
                      </tr>
                    </thead>
                    <DiscordBotsList />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

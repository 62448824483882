import React from "react";
import useAxios from "../../utils/useAxios";
import { useQuery } from "react-query";
import LoadingDots from "../animations/LoadingDots";
import { Link } from "react-router-dom";

export default function DiscordBotsList() {
  const api = useAxios();

  const fetchData = async () => {
    const response = await api.get("/discordbot/");
    return response.data;
  };

  const { isLoading, error, data } = useQuery("discordbots", fetchData, {
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <tbody>
        <tr>
          <td colSpan={6}>
            <LoadingDots />
          </td>
        </tr>
      </tbody>
    );

  if (error)
    return (
      <tbody>
        <tr>
          <td colSpan={6} className="text-center">
            An unexpected error occured: {error.message}
          </td>
        </tr>
      </tbody>
    );

  return (
    <tbody>
      {data ? (
        data.map((bot) => (
          <tr>
            <th scope="row">
              <div className="media align-items-center">
                <div className="media-body">
                  <a href="#!">
                    <span className="mb-0 h4 text-cadet-grey">
                      <i className={"bi bi-app-indicator"} />
                      &nbsp;{" "}
                      <Link to={`/discordbot/${bot.id}/`}>
                        {bot.name ? bot.name : "N/A"}
                      </Link>
                    </span>
                  </a>
                </div>
              </div>
            </th>
            <td>{bot.plan ? bot.plan : "N/A"}</td>
            <td>{bot.rate ? "$" + bot.rate : "N/A"}</td>
            <td>
              {bot.auto_renew ? (
                <i className="bi bi-check-circle-fill text-success" />
              ) : (
                <i class="bi bi-x-circle-fill text-danger" />
              )}
            </td>
            <td>{bot.id ? bot.id : "N/A"}</td>
            <td>
              {bot.running ? (
                <i className="bi bi-check-circle-fill text-success" />
              ) : (
                ""
              )}{" "}
              {bot.status ? bot.status : "N/A"}
            </td>
          </tr>
        ))
      ) : (
        <tr>No data</tr>
      )}
    </tbody>
  );
}

import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import SubscriptionStatus from "../SubscriptionStatus";
import { toast } from "react-toastify";
import LoadingDots from "../animations/LoadingDots";

export default function VPSStats({ id }) {
  const url = "/vps/" + id + "/";
  const api = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [autoRenew, setAutoRenew] = useState(false);
  const [paymentBtnHold, setPaymentBtnHold] = useState(false);
  const [action, setAction] = useState(null);
  const [subscriptionCreate, setSubscriptionCreate] = useState(false);

  const changeAutoRenewStatus = async (e) => {
    setAutoRenew(!autoRenew);
    const response = await api.put(url, { auto_renew: !autoRenew });
    if (response.status === 200) {
      let vps = queryClient.getQueryData("vps");
      if (vps !== undefined) {
        vps.forEach((item) => {
          if (item.id === id) {
            item.auto_renew = !autoRenew;
          }
        });
        queryClient.setQueryData("vps", vps);
      }
      toast.success(`Auto renew turned ${!autoRenew ? "on" : "off"}`);
    }
  };
  const subscribePlan = async (action) => {
    setPaymentBtnHold(true);
    const response = await api.post(url, { action: action }).catch((error) => {
      if (error.response.status === 402) {
        toast.error(
          "Not enough credits to complete the transaction. Recharge your account."
        );
        navigate("/billing/");
      } else if (error.response.status === 501) {
        toast.error("Not Implemented");
        setPaymentBtnHold(false);
        setSubscriptionCreate(false);
      } else {
        toast.error("Something went wrong. Reload the page and try again.");
      }
    });
    if (response.status === 200) {
      let orders = queryClient.getQueryData("orders");
      if (orders !== undefined) {
        orders.unshift(response.data);
        queryClient.setQueryData("orders", orders);
      }
      queryClient.invalidateQueries(id);
      toast.success("Successfully subscribed");
    }
    setSubscriptionCreate(false);
    setPaymentBtnHold(false);
  };

  const fetchData = async () => {
    const response = await api.get(url);
    return response.data;
  };
  const { isLoading, error, data } = useQuery(id, fetchData, {
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (data) {
      setAutoRenew(data.auto_renew);
    }
  }, [data]);
  if (isLoading) return <LoadingDots />;
  if (error) return navigate("/notfound/");

  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="header-title">Server Details</h4>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="d-flex align-items-center border border-light rounded py-1 px-2 mb-2">
                  <div className="flex-shrink-0 me-2">
                    <i className="mdi mdi-checkbox-marked-circle-outline widget-icon rounded-circle bg-danger-lighten text-danger"></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fw-semibold my-0">Name</h5>
                    <p className="mb-0 font-weight-normal">
                      {data.name ? data.name : "N/A"}
                    </p>
                  </div>
                  <a
                    href="#!"
                    className="text-muted"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Name of the resource"
                  >
                    <i className="fa fa-info-circle text-muted h4  my-0"></i>
                  </a>
                </div>
                <div className="d-flex align-items-center border border-light rounded py-1 px-2 mb-2">
                  <div className="flex-shrink-0 me-2">
                    <i className="mdi mdi-send widget-icon rounded-circle bg-success-lighten text-success"></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fw-semibold my-0">Resource ID</h5>
                    <p className="mb-0 font-weight-normal">
                      {data.id ? data.id : "N/A"}
                    </p>
                  </div>
                  <a
                    href="#!"
                    className="text-muted"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Unique resource id allocated to each resource"
                  >
                    <i className="fa fa-info-circle text-muted h4  my-0"></i>
                  </a>
                </div>
                <div className="d-flex align-items-center border border-light rounded py-1 px-2">
                  <div className="flex-shrink-0 me-2">
                    <i className="mdi mdi-send widget-icon rounded-circle bg-success-lighten text-success"></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fw-semibold my-0">Location</h5>
                    <p className="mb-0 font-weight-normal">
                      {data.location ? data.location : "N/A"}
                    </p>
                  </div>
                  <a
                    href="#!"
                    className="text-muted"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Location of the vps allocated"
                  >
                    <i className="fa fa-info-circle text-muted h4  my-0"></i>
                  </a>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center border border-light rounded py-1 px-2 mb-2">
                  <div className="flex-shrink-0 me-2">
                    <i className="mdi mdi-checkbox-marked-circle-outline widget-icon rounded-circle bg-danger-lighten text-danger"></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fw-semibold my-0">CPU</h5>
                    <p className="mb-0 font-weight-normal">
                      {data.plan.cpu ? data.plan.cpu + "vCore" : "N/A"}
                    </p>
                  </div>
                  <a
                    href="#!"
                    className="text-muted"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="CPU Allocated for the resource"
                  >
                    <i className="fa fa-info-circle text-muted h4  my-0"></i>
                  </a>
                </div>
                <div className="d-flex align-items-center border border-light rounded py-1 px-2 mb-2">
                  <div className="flex-shrink-0 me-2">
                    <i className="mdi mdi-send widget-icon rounded-circle bg-success-lighten text-success"></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fw-semibold my-0">RAM</h5>
                    <p className="mb-0 font-weight-normal">
                      {data.plan.ram ? data.plan.ram + "GB" : "N/A"}
                    </p>
                  </div>
                  <a
                    href="#!"
                    className="text-muted"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Memory allocated for the resource"
                  >
                    <i className="fa fa-info-circle text-muted h4  my-0"></i>
                  </a>
                </div>
                <div className="d-flex align-items-center border border-light rounded py-1 px-2">
                  <div className="flex-shrink-0 me-2">
                    <i className="mdi mdi-send widget-icon rounded-circle bg-success-lighten text-success"></i>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fw-semibold my-0">Disk</h5>
                    <p className="mb-0 font-weight-normal">
                      {data.plan.memory ? data.plan.memory + "GB" : "N/A"}
                    </p>
                  </div>
                  <a
                    href="#!"
                    className="text-muted"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Disk Storage allocated for the resource"
                  >
                    <i className="fa fa-info-circle text-muted h4  my-0"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!autoRenew ? (
          <div className="card mt-1 mb-1">
            <div className="card-body">
              {data.days_to_expire !== null ? (
                <div
                  className={`alert ${
                    data.days_to_expire ? " alert-warning" : " alert-danger"
                  } alert-dismissible fade show`}
                  role="alert"
                >
                  <span className="alert-icon">
                    <i className="ni ni-bell-55"></i>
                  </span>
                  <span className="alert-text">
                    Your plan will expire
                    {data.days_to_expire
                      ? " in " + data.days_to_expire + " days "
                      : " today"}
                    . Renew now!
                  </span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="col-lg-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="header-title">Plan Details</h4>
            </div>
            <SubscriptionStatus status={data.status ? data.status : "N/A"} />
            <div className="d-flex align-items-center border border-light rounded p-2 mb-2">
              <div className="flex-shrink-0 me-2">
                <i className="mdi mdi-checkbox-marked-circle-outline widget-icon rounded-circle bg-danger-lighten text-danger"></i>
              </div>
              <div className="flex-grow-1">
                <h5 className="fw-semibold my-0">Plan</h5>
                <p className="mb-0 font-weight-normal">
                  {data.plan.name ? data.plan.name : "N/A"}
                </p>
              </div>
              <a
                href="#!"
                className="text-muted"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Name of subscribed plan"
              >
                <i className="fa fa-info-circle text-muted h4  my-0"></i>
              </a>
            </div>
            {data.status === "Active" ? (
              <div className="d-flex align-items-center border border-light rounded p-2 mb-2">
                <div className="flex-shrink-0 me-2">
                  <i className="mdi mdi-progress-pencil widget-icon rounded-circle bg-warning-lighten text-warning"></i>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fw-semibold my-0">Renewal Date</h5>
                  <p className="mb-0 font-weight-normal">
                    {data.subscribed_until_date
                      ? data.subscribed_until_date
                      : "N/A"}
                  </p>
                </div>
                {!autoRenew ? (
                  <a
                    href="#!"
                    className="btn btn-sm btn-outline-default"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Extend the renewal date"
                    onClick={() => {
                      setSubscriptionCreate(true);
                      setAction("Renew");
                    }}
                  >
                    Renew now
                  </a>
                ) : (
                  <a
                    href="#!"
                    className="text-muted"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Date of plan auto renewal"
                  >
                    <i className="fa fa-info-circle text-muted h4  my-0"></i>
                  </a>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="d-flex align-items-center border border-light rounded p-2 mb-5">
              <div className="flex-shrink-0 me-2">
                <i className="mdi mdi-progress-pencil widget-icon rounded-circle bg-warning-lighten text-warning"></i>
              </div>
              <div className="flex-grow-1">
                <h5 className="fw-semibold my-0">Auto Renew</h5>
                <p className="mb-0 font-weight-normal">
                  {!autoRenew ? "OFF" : "ON"}
                </p>
              </div>
              <label className="custom-toggle  custom-toggle-default">
                <input
                  type="checkbox"
                  checked={autoRenew}
                  onChange={changeAutoRenewStatus}
                />
                <span className="custom-toggle-slider rounded-circle"></span>
              </label>
            </div>
            {data.status !== "Active" ? (
              <button
                className="btn btn-sm btn-outline-default w-100 mb-5"
                onClick={() => {
                  setSubscriptionCreate(true);
                  setAction("Subscribe");
                }}
              >
                Make Payment
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div
        class={`modal fade ${subscriptionCreate ? "show" : ""}`}
        id="mcSubscriptionModal"
        tabindex="-1"
        role="dialog"
        style={{ display: subscriptionCreate ? "block" : "none" }}
        aria-labelledby="mcSubscriptionModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {action}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="bg-white rounded">
                <div class="row">
                  <div class="col-md-6">
                    <h1 class="text-uppercase">Invoice</h1>
                    {/* <div class="billed"><span class="font-weight-bold text-uppercase">Billed:</span><span class="ml-1">Jasper Kendrick</span></div> */}
                    <div class="billed">
                      <span class="font-weight-bold text-uppercase">Date:</span>
                      <span class="ml-1">
                        {new Date().toJSON().slice(0, 10).replace(/-/g, "-")}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 text-right mt-3">
                    <h4 class="text-danger mb-0">Nodeslice Hosting</h4>
                    <span>nodeslice.com</span>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Plan</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            vps Hosting {action === "Renew" ? "(+30 days)" : ""}
                          </td>
                          <td>{data.plan.name}</td>
                          <td>${data.plan.rate}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <b>Total</b>
                          </td>
                          <td>
                            <b>${data.plan.rate}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="border border-default rounded p-2">
                  <small>
                    <b>
                      <i className="fa fa-info-circle text-default h4  my-0"></i>{" "}
                      Note
                    </b>
                    :{" "}
                    {action === "Subscribe"
                      ? "vps Server will only be created after successful payment"
                      : "After renewal, 30 days will be added to existing billing cycle"}
                  </small>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => setSubscriptionCreate(false)}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-default"
                disabled={paymentBtnHold}
                onClick={() => subscribePlan(action)}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

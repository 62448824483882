import React from 'react'
import useAxios from '../../utils/useAxios'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import LoadingDots from '../animations/LoadingDots'
export default function McHostList() {
    
    const api = useAxios()

    const fetchData = async() => {
        const response = await api.get("/minecraft/");
        return response.data
    }

    const { isLoading, error, data } = useQuery('minecraft', fetchData, {refetchOnWindowFocus: false});

    if (isLoading) return <tbody><tr><td colSpan={6}><LoadingDots/></td></tr></tbody>
    if (error) return <tbody><tr><td colSpan={6} className="text-center">An unexpected error occured: {error.message}</td></tr></tbody>

    return (
        <tbody>
        {data ? 
        data.map((host) => (
        <tr>
          <th scope="row">
            <i className="bi bi-box"></i> <Link to={`/minecraft/${host.id}/`}>{host.name ? host.name : "N/A"}</Link>
          </th>
          <td>
            {host.plan ? host.plan : "N/A"}
          </td>
          <td>
            {host.rate ? "$"+host.rate : "N/A"}
          </td>   
          <td>
            {host.auto_renew ? <i className="bi bi-check-circle-fill text-success"/> : <i class="bi bi-x-circle-fill text-danger"/>}
          </td>          
          <td>
            {host.id ? host.id : "N/A"}
          </td>                       
          <td>
            {host.running ? <i className="bi bi-check-circle-fill text-success"/> : ""} {host.status ? host.status : "N/A"}
          </td>                                  
        </tr>
        ))
        :
        <tr>No data</tr>
        }
      </tbody>
    )
}

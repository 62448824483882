import React from "react";

export default function SubscriptionStatus({ status }) {
  let alertClass = "alert-default";

  switch (status) {
    case "Expired":
      alertClass = "alert-danger";
      break;
    case "Not Started":
      alertClass = "alert-info";
      break;
    case "Active":
      alertClass = "alert-success";
      break;
    default:
      break;
  }
  return (
    <div className={`alert ${alertClass} mt-2`} role="alert">
      <i className="uil-folder-heart me-1 h4 align-middle"></i> Subscription{" "}
      {status}
    </div>
  );
}

import React from "react";
import Sidenav from "../bases/Sidenav";
import Navbar from "../bases/Navbar";
import WalletWidget from "../components/WalletWidget";
import Footer from "../bases/Footer";
import TransactionList from "../components/sections/TransactionList";
import OrderList from "../components/sections/OrderList";
import PaypalCard from "../components/PaypalCard";

export default function Billing() {
  return (
    <div>
      <Sidenav />
      <div className="main-content">
        <Navbar />
        <div className="header bg-semi-dark pt-5 pt-md-8"></div>
        <div className="container-fluid mt--5">
          <div className="row">
            <div className="col-xl-6 col-md-12 mt-2">
              <div className="card">
                <div className="card-header">
                  <h3 className="mb-0">Your Orders</h3>
                </div>
                <div className="table-responsive">
                  <table className="table table-flush" id="datatable-buttons">
                    <thead className="thead-light">
                      <tr>
                        <th>id</th>
                        <th>Amount</th>
                        <th>Creation time</th>
                        <th>Resource Id</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <OrderList />
                  </table>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12 mt-2">
              <div className="row">
                <div
                  className={"col-xl-6 col-md-12 d-none d-lg-none d-xl-block"}
                >
                  <PaypalCard />
                </div>
                <div
                  className={"col-xl-6 col-md-12 d-none d-lg-none d-xl-block"}
                >
                  <WalletWidget />
                </div>
                <div className="col-xl-12 mt-2">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="mb-0">Your Transactions</h3>
                    </div>
                    <div className="table-responsive">
                      <table
                        className="table table-flush"
                        id="datatable-buttons"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th>id</th>
                            <th>Amount</th>
                            <th>Creation Time</th>
                            {/* <th>Order/Purpose</th> */}
                            <th>Status</th>
                            <th>Invoice</th>
                          </tr>
                        </thead>
                        <TransactionList />
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

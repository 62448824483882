import React, { useState } from "react";
import Sidenav from "../../bases/Sidenav";
import Footer from "../../bases/Footer";
import Navbar from "../../bases/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import PterodactylCard from "../../components/PterodactylCard";
import { toast } from "react-toastify";
import useAxios from "../../utils/useAxios";
import { useQueryClient } from "react-query";
import DiscordBotStats from "../../components/stats/DiscordBotStats";

export default function DiscordBotManage() {
  const [agree, setAgree] = useState(true);
  const { botId } = useParams();
  const api = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const terminateMcServer = async () => {
    const response = await api
      .delete(`/discordbot/${botId}/`)
      .catch((error) => {
        toast.error("Something went wrong... Contact administrator");
      });
    if (response.status === 204) {
      toast.success("Server Terminated");
      let discordbots = queryClient.getQueryData("discordbots");
      if (discordbots !== undefined) {
        discordbots.forEach((item) => {
          if (item.id === botId) {
            let index = discordbots.indexOf(item);
            if (index > -1) {
              discordbots.splice(index, 1);
            }
          }
        });
        queryClient.setQueryData("discordbots", discordbots);
      }
      navigate("/discordbot/");
    }
  };
  return (
    <div>
      <Sidenav />
      <div className="main-content">
        <Navbar />
        <div className="header bg-semi-dark pt-5 pt-md-8"></div>
        <div className="container-fluid mt--5">
          <div className="row">
            <div className="col-xl-8 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="mb-0">Bot Host Panel</h3>
                </div>
                <div className="card-body">
                  <DiscordBotStats id={botId} />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-12">
              <PterodactylCard />
            </div>
            <div className="col-xl-12">
              <div class="card mt-5">
                <div className="card-header">
                  <h3 className="mb-0">Danger Zone</h3>
                </div>
                <div class="card-body">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={() => setAgree(!agree)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      I understand this action cannot be undone. Credits used
                      will not be refunded.
                    </label>
                  </div>
                  <button
                    className="btn btn-sm btn-danger"
                    disabled={agree}
                    onClick={terminateMcServer}
                  >
                    Terminate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

import React, { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import logo from "../images/logo.svg";

export default function Authentication() {
  let { loginUser, registerUser, resendVerification, resetPassword } =
    useContext(AuthContext);
  const [email, setEmail] = useState(null);
  return (
    <div className="fullrock config sec-bg2 motpath bg-semi-dark">
      <section className="fullrock-content">
        <div className="container bg-white py-7 px-5 rounded-5">
          <div className="sec-main login-logo mt--5">
            <img src={logo} />
          </div>
          <div className="sec-main">
            <h1 id={"signup"}>
              <b>Login or Create a New Account</b>
            </h1>
            <p className="mb-3">
              If you are a returning customer, please Login if not, create a new
              account.
            </p>
            <div className="nav-wrapper">
              <ul
                className="nav nav-pills nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link mb-sm-3 mb-md-0 btn btn-default active border-0"
                    id="tabs-icons-text-1-tab"
                    data-toggle="tab"
                    href="#tabs-icons-text-1"
                    role="tab"
                    aria-controls="tabs-icons-text-1"
                    aria-selected="true"
                  >
                    Already a customer?
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link mb-sm-3 mb-md-0 btn btn-default border-0"
                    id="tabs-icons-text-2-tab"
                    data-toggle="tab"
                    href="#tabs-icons-text-2"
                    role="tab"
                    aria-controls="tabs-icons-text-2"
                    aria-selected="false"
                  >
                    Create a new Account
                  </a>
                </li>
              </ul>
            </div>
            <div className="card shadow">
              <div className="card-body">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="tabs-icons-text-1"
                    role="tabpanel"
                    aria-labelledby="tabs-icons-text-1-tab"
                  >
                    <div className="col-sm-12">
                      <div className="table tabs-item active">
                        <div className="cd-filter-block mb-0">
                          <h3 className="m-0">Existing Customer Login</h3>
                          <div className="cd-filter-content mt-2">
                            <form
                              action="#"
                              onSubmit={loginUser}
                              method="post"
                              className="comments-form"
                            >
                              <div className="row">
                                <div className="col-md-6 position-relative mb-2">
                                  <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    required=""
                                    className={"form-control"}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-6 position-relative">
                                  <input
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    required=""
                                    className={"form-control"}
                                  />
                                </div>
                                <div className="col-md-12 mt-2 position-relative">
                                  <ul className="col-md-12 position-relative mt-2 ml-2">
                                    <li>
                                      <input
                                        name="rememberme"
                                        type="checkbox"
                                        id="checkbox"
                                        className={"form-check-input"}
                                      />
                                      <label
                                        htmlFor="checkbox"
                                        className="form-check-label"
                                      >
                                        {" "}
                                        Remember Me
                                      </label>
                                    </li>
                                  </ul>
                                  <button
                                    type="submit"
                                    value="login"
                                    id="login"
                                    className="btn btn-default mt-0 mb-3 me-3"
                                  >
                                    <i className="fas fa-lock" /> Login
                                  </button>
                                  &nbsp;&nbsp;&nbsp;
                                  <a
                                    className="golink me-3 position-relative"
                                    href="#!"
                                    onClick={() => resetPassword(email)}
                                  >
                                    Forgot Password?
                                  </a>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tabs-icons-text-2"
                    role="tabpanel"
                    aria-labelledby="tabs-icons-text-2-tab"
                  >
                    <div className="cd-filter-block mb-0">
                      <h3>Sign Up</h3>
                      <div className="cd-filter-content">
                        <form
                          action="#"
                          onSubmit={registerUser}
                          method="post"
                          name="orderfrm"
                          className="comments-form"
                        >
                          <div>
                            <small>Personal Information</small>
                          </div>
                          <div className="row mb-5">
                            <div className="col-md-6 position-relative mt-2 mb-2">
                              <input
                                type="text"
                                name="firstname"
                                id="inputFirstName"
                                placeholder="First Name"
                                required=""
                                className={"form-control"}
                              />
                            </div>
                            <div className="col-md-6 position-relative mt-2 mb-2">
                              <input
                                type="text"
                                name="lastname"
                                id="inputLastName"
                                placeholder="Last Name"
                                required=""
                                className={"form-control"}
                              />
                            </div> 
                            <div className="col-md-6 position-relative mt-2 mb-2">
                              <input
                                type="email"
                                name="email"
                                id="inputEmail"
                                placeholder="Email Address"
                                required=""
                                className={"form-control"}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div className="col-md-6 position-relative mt-2 mb-2">
                              <input
                                type="text"
                                name="username"
                                id="inputPhone"
                                placeholder="Username"
                                required=""
                                className={"form-control"}
                              />
                            </div>                           
                          </div>
                          <div>
                            <small>Account Security</small>
                          </div>
                          <div className="row mb-5 mt-1">
                            <div className="col-md-6 position-relative mb-2">
                              <input
                                className={"form-control"}
                                type="password"
                                name="password"
                                placeholder="Password"
                                required=""
                              />
                            </div>
                            <div className="col-md-6 position-relative">
                              <input
                                className={"form-control"}
                                type="password"
                                name="password_confirmation"
                                placeholder="Confirm Password"
                                required=""
                              />
                            </div>
                          </div>
                          <button
                            type="submit"
                            value="login"
                            id="login"
                            className="btn btn-default mt-0 mb-3 me-3"
                          >
                            {" "}
                            Register
                          </button>
                          &nbsp;&nbsp;&nbsp;
                          <a
                            className="golink me-3 position-relative"
                            href="#!"
                            onClick={() => resendVerification(email)}
                          >
                            Resend Verification Email?
                          </a>
                          <br />
                          <br />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

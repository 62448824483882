import React, { useContext } from "react";
import Sidenav from "../bases/Sidenav";
import Navbar from "../bases/Navbar";
import Footer from "../bases/Footer";
import McHostList from "../components/sections/McHostList";
import ResourceContext from "../context/ResourceContext";

export default function McHosting() {
  const { setMcHostCreate } = useContext(ResourceContext);

  return (
    <div>
      <Sidenav />
      <div className="main-content">
        <Navbar />
        <div className="header bg-semi-dark pt-5 pt-md-8"></div>
        <div className="container-fluid mt--4">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Minecraft Servers</h3>
                    </div>
                    <div className="col text-right">
                      <button
                        href="#!"
                        className="btn btn-sm btn-default"
                        onClick={() => setMcHostCreate(true)}
                      >
                        Create Server
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Rate (pm)</th>
                        <th scope="col">Auto Renew</th>
                        <th scope="col">Resource Id</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <McHostList />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
